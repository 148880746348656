import React, { useState } from "react"
import { InView } from "react-intersection-observer"
import SubscribeFormContainer from "@containers/SubscribeForm"
import { MiddleWhiteCircle, SmallBlueCircle } from "./PostSubscribeCircles"
import { BlogContentDataType } from "@typings/requests/home/blog"
import "./post-subscribe.scss"

interface PostSubscribePropsTypes extends BlogContentDataType {
  lang: string
  isFeaturedPosts: boolean
}

const PostSubscribe: React.FC<PostSubscribePropsTypes> = ({
  isFeaturedPosts,
  subscribeTitle,
  subscribeButton,
  subscribeFailLabel,
  subscribeInputError,
  subscribeInputLabel,
  subscribeSuccessLabel,
  lang,
}) => {
  const [isInView, setIsInView] = useState(false)

  return (
    <InView as="section" triggerOnce threshold={[0.1]} onChange={setIsInView}>
      <section className="container post-subscribe__container">
        <SmallBlueCircle isView={isInView} />
        {isFeaturedPosts && <MiddleWhiteCircle isView={isInView} />}
        <div className="post-subscribe">
          <h2 className="text text--h2 post-subscribe__title">
            {subscribeTitle}
          </h2>

          <SubscribeFormContainer
            className="post-subscribe__form"
            inline
            subscribeButton={subscribeButton}
            subscribeFailLabel={subscribeFailLabel}
            subscribeInputError={subscribeInputError}
            subscribeInputLabel={subscribeInputLabel}
            subscribeSuccessLabel={subscribeSuccessLabel}
            subscribeTitle={subscribeTitle}
            lang={lang}
          />
        </div>
      </section>
    </InView>
  )
}

export default PostSubscribe
